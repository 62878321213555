import { render, staticRenderFns } from "./uploadlaporan.vue?vue&type=template&id=a01fc48e&"
import script from "./uploadlaporan.vue?vue&type=script&lang=js&"
export * from "./uploadlaporan.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports